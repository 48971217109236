.footer {
  padding-bottom: map-get($spacers, 7);

  .sign {
    margin-bottom: map-get($spacers, 6);

    @include respond-below(sm) {
      width: 200px;
    }
  }

  .nav-item {
    margin-bottom: map-get($spacers, 4);

    .nav-link {
      font-weight: 700;
      // display: inline;
      // text-transform: uppercase;
      text-decoration: underline;
    }
  }
}
