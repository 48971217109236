// @function calculateRem($size) {
//     $remSize: $size / 16px;
//     @return #{$remSize}rem;
//   }
//   Creating the mixin

//   Next we create a mixin that calls the function:

// @mixin fontSize($size) {
//     font-size: $size; //Fallback in px
//     font-size: calculateRem($size);
// }

// // Function for converting a px based font-size to rem.
// @function calculateRem($size) {
//     @return $size / 16px * 1rem;
//    }

// // Mixin that will include the fall back px declaration as well as the calculated rem value.
// @mixin fontSize($size) {
//     font-size: $size;
//     font-size: calculateRem($size);
// }

@function rem($size) {
    @return $size / 16px * 1rem;
}

@mixin font-source-sans($size: false, $colour: false, $weight: false,  $lh: false) {
    // font-family: $font-family;
    @if $size { font-size: rem($size); }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh==normal {
        line-height: normal;
    } @else {
        line-height: rem($lh);
    }
}