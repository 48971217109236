@import url("https://use.typekit.net/xrg2dyf.css");


// lato italic
// font-family: lato, sans-serif;
// font-weight: 400;
// font-style: italic;

// lato bold italic
// font-family: lato, sans-serif;
// font-weight: 700;
// font-style: italic;

// lato bold
// font-family: lato,sans-serif;
// font-weight: 700;
// font-style: normal;

// lato regular
// font-family: lato,sans-serif;
// font-weight: 400;
// font-style: normal;

// komet light
// font-family: komet-sc,sans-serif;
// font-weight: 300;
// font-style: normal;


body {
    font-family: lato, sans-serif;
}

h1,
.headline-one {
    font-family: komet-sc,sans-serif;
    @include font-source-sans(52px, $h1-color, 300, normal);
    margin-bottom: map-get($spacers, 4);

    @include respond-below(sm) {
      font-size: map-get($spacers, 5);
    }
}

h2,
.headline-two {
    @include font-source-sans(24px, $h2-color, 500, normal);
}

h3,
.headline-three {
    font-family: komet-sc,sans-serif;
    @include font-source-sans(24px, $h3-color, 700, 34px);
    margin-bottom: map-get($spacers, 5);
}

h4,
.headline-four {
    font-family: komet-sc,sans-serif;
    @include font-source-sans(30px, $h4-color, 300, 36px);
    color: $black;
}

h5,
.headline-five {
    font-family: komet-sc,sans-serif;
    @include font-source-sans(40px, $h5-color, 700, normal);
}

p,
.paragraph {
    @include font-source-sans(18px, $p-color, normal, 26px);
    margin-bottom: map-get($spacers, 5);

    &.highlight {
        font-family: komet-sc,sans-serif;
        @include font-source-sans(39px, $c-highlight, 700, normal);
        // letter-spacing: 4px;
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 6);



        &::before {
            content: '';
            display: block;
            height: 4px;
            width: 35%;
            position: relative;
            top: rem(-40px);
            background-color: $c-mulberry;
        }

        & > a {
            font-family: komet-sc,sans-serif;
            @include font-source-sans(40px, $a-color, 300, normal);
        }
    }
}

a,
.link {
    @include font-source-sans(18px, $a-color, normal, normal);
    // letter-spacing: rem(2px);
    &.link-cta {
        // @include font-source-sans(18px, $c-black, 700, normal);
        font-weight: 700;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $c-black;
        text-decoration: none;
        display: inline-block;

        &::after {
            content: '';
            display: block;
            height: 2px;
            width: 100%;
            position: relative;
            bottom: rem(-10px);
            background-color: $c-curious-blue;
        }
    }
    &.link-back {
      font-family: komet-sc,sans-serif;
      margin-top: map-get($spacers, 5);
      display: inline-block;
      // @include font-source-sans(16px, #b9bfb5, 700, normal);
    }


}
