.navbar-fixed-left {
  @include respond-above(sm) {
      width: 140px;
      position: fixed;
      top: 0;
      border-radius: 0;
    }
}

.navbar-fixed-left .navbar-nav>li {
  @include respond-above(sm) {
    float: none;
    width: 139px;
  }
}

.navbar-fixed-left+main {

  @include respond-above(sm) {
    // min 768px
    padding-left: rem(224px);
  }

  @include respond-above(md) {
    // min 992px
    padding-left: rem(220px);
  }

  @include respond-above(lg) {
    padding-left: rem(360px);
  }
}

/* On using dropdown menu (To right shift popuped) */
.navbar-fixed-left .navbar-nav>li>.dropdown-menu {
    margin-top: -50px;
    margin-left: 140px;
}

.navbar {
  margin-top: map-get($spacers, 2);

  @include respond-above(sm) {
      margin-top: rem(40px);
      margin-left: rem(40px);
    }

    @include respond-above(lg) {
      // min 1200px
      margin-left: rem(165px);
    }

    .navbar-toggler {
      color: rgba(0,0,0,.5);
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    }

    .navbar-collapse {
      @include respond-below(sm) {
        margin-right: rem(15px);
        margin-top: map-get($spacers, 4);
      }
    }

    &.navbar-expand-md {
      @include respond-above(sm) {
        // min 768px
        flex-flow: column;
        align-items: flex-start;
      }
    }

    .navbar-brand {
        font-family: komet-sc,sans-serif;
        @include font-source-sans(30px, $p-color, 300, 33px);
        white-space: inherit;
        text-decoration: none;
        letter-spacing: 0;
        padding: 0;
        margin: 0;
        width: 165px;
        @include respond-above(sm) {
          margin-bottom: rem(116px);
        }

        &::after {
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          position: relative;
          bottom: rem(-10px);
          background-color: $c-mulberry;
      }
    }

    .nav-item {
      margin-bottom: map-get($spacers, 2);
      @include respond-below(sm) {
        text-align: right;
      }

      .nav-link {
        padding: 0;
        @include font-source-sans(20px, #000, 700, normal);

        @include respond-below(sm) {
          padding: map-get($spacers, 3) 0;
          display: inline-block;
        }
      }

      &.is-active {
        > .nav-link {
          text-decoration: underline;
          text-underline-offset: 5px;
          text-decoration-thickness: 2px;
        }
      }

      &.first.depth-1 {
        margin-bottom: map-get($spacers, 3);

        & > .nav-link {
          font-family: komet-sc,sans-serif;
          @include font-source-sans(16px, #b9bfb5, 700, normal);
          margin-bottom: map-get($spacers, 1);
        }
      }

      &.depth-1.last .nav-link{
        background-color: $c-curious-blue;
        text-transform: uppercase;
        padding: rem(1px) rem(30px) rem(1px) rem(10px);
        margin-top: map-get($spacers, 5);
        font-family: komet-sc,sans-serif;
        color: $c-white;
        font-weight: 500;

        @include respond-below(sm) {
          padding-left: rem(30px);
          padding-right: rem(10px);
        }

      }
      &.depth-1.last .nav-link.is-active {
        .nav-link {

          text-decoration: none;
        }
      }
    }
}
