@import "3-custom-bootstrap-variables"; // custom variables

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";

// own styles (feel free to add more)
@import "media-querries";
@import "base";
@import "typograhpy";
@import "nav";
@import "news";
@import "footer";

main {
  margin-top: map-get($spacers, 5);

  @include respond-above(sm) {
    margin-top: rem(232px);
  }
}

.section {
  margin-bottom: rem(126px);
}

.intro {
  margin-bottom: map-get($spacers, 7);
  @include respond-below(sm) {
    &.intro__lowspace {
      margin-bottom: map-get($spacers, 4);
    }
  }
}

hr {
  margin: rem(43px) 0;
  border-color: $c-mulberry;
}

.vita {
  .table-border {
    margin: 0;
    margin-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
    border-top: 1px solid #b9bfb5;

  }
}

.contact {
  .link {
    margin-bottom: map-get($spacers, 5);
    font-family: komet-sc, sans-serif;
  }

  .icon {
    display: block;
    position: relative;
    line-height: 30px;
    padding-left: map-get($spacers, 5);

    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      position: absolute;
      left: 0;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &.email {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231AA6DF' fill-rule='nonzero'%3E%3Cg%3E%3Cpath d='M27.188 0H2.813C1.258 0 0 1.231 0 2.75v16.5C0 20.769 1.26 22 2.813 22h24.375C28.74 22 30 20.769 30 19.25V2.75C30 1.231 28.74 0 27.187 0zm0 2.75v2.338c-1.314 1.046-3.409 2.673-7.886 6.1-.987.76-2.942 2.583-4.302 2.562-1.36.021-3.315-1.803-4.302-2.561C6.221 7.76 4.126 6.134 2.812 5.088V2.75h24.375zM2.813 19.25V8.617c1.342 1.045 3.246 2.512 6.148 4.734 1.28.986 3.524 3.162 6.039 3.149 2.503.013 4.717-2.131 6.038-3.148 2.902-2.222 4.807-3.69 6.15-4.735V19.25H2.813z' transform='translate(-450.000000, -487.000000) translate(450.000000, 487.000000)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &.phone {
      &::before {
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231AA6DF' fill-rule='nonzero'%3E%3Cpath d='M477.983 563.346l-5.688-1.313c-.618-.142-1.252.18-1.503.76l-2.625 6.125c-.23.536-.077 1.165.377 1.532l3.314 2.712c-1.969 4.195-5.409 7.684-9.69 9.69l-2.713-3.313c-.372-.454-.995-.608-1.531-.378l-6.125 2.625c-.585.257-.908.891-.766 1.51l1.313 5.687c.137.59.662 1.017 1.28 1.017C467.63 590 479 578.653 479 564.626c0-.613-.421-1.143-1.017-1.28z' transform='translate(-451.000000, -562.000000)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }

    &.instagram {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231AA6DF'%3E%3Cpath d='M475.916 748.253c-.487 1.225-1.43 2.169-2.661 2.662-1.843.731-6.216.562-8.252.562-2.036 0-6.415.163-8.252-.562-1.224-.487-2.167-1.43-2.66-2.662-.731-1.843-.563-6.216-.563-8.253s-.162-6.417.562-8.253c.488-1.225 1.43-2.169 2.661-2.662 1.843-.731 6.216-.562 8.252-.562 2.036 0 6.415-.163 8.252.562 1.224.487 2.167 1.43 2.66 2.662.732 1.843.563 6.216.563 8.253s.169 6.417-.562 8.253m2.986-14.026c-.107-2.243-.619-4.23-2.262-5.867-1.636-1.637-3.623-2.15-5.865-2.262-2.311-.13-9.239-.13-11.55 0-2.236.107-4.223.619-5.865 2.256-1.643 1.637-2.15 3.624-2.262 5.867-.13 2.311-.13 9.24 0 11.552.107 2.243.619 4.23 2.262 5.867 1.642 1.637 3.623 2.15 5.865 2.262 2.311.13 9.239.13 11.55 0 2.242-.107 4.229-.619 5.865-2.262 1.637-1.637 2.15-3.624 2.262-5.867.13-2.312.13-9.234 0-11.546m-4.754-1.7c0 .931-.75 1.675-1.674 1.675-.93 0-1.674-.75-1.674-1.674 0-.925.75-1.675 1.674-1.675.924 0 1.674.75 1.674 1.675m-9.145 12.14c-2.567 0-4.666-2.094-4.666-4.668 0-2.574 2.093-4.667 4.666-4.667 2.574 0 4.666 2.093 4.666 4.667s-2.099 4.667-4.666 4.667m0-11.846c-3.973 0-7.177 3.205-7.177 7.179s3.204 7.179 7.177 7.179c3.973 0 7.177-3.205 7.177-7.179s-3.204-7.179-7.177-7.179' transform='translate(-451.000000, -726.000000)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }

    &.address {
      &::before {
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='31' viewBox='0 0 24 31'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231AA6DF' fill-rule='nonzero'%3E%3Cpath d='M463.767 669.375C454.686 656.62 453 655.312 453 650.625c0-6.42 5.373-11.625 12-11.625s12 5.205 12 11.625c0 4.687-1.686 5.996-10.767 18.75-.596.834-1.87.833-2.466 0zM465 655.469c2.761 0 5-2.169 5-4.844 0-2.675-2.239-4.844-5-4.844s-5 2.169-5 4.844c0 2.675 2.239 4.844 5 4.844z' transform='translate(-453.000000, -639.000000)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }
}

.quote {
  margin-top: map-get($spacers, 7);
}

.references {
  a {
    display: inline-block;
    margin-bottom: rem(32px);

    &:hover {
      position: relative;
      &:not(.link-cta, .references-filter-item a) {

        &::before {
          content: "Mehr Details";
          transition: all 1s linear;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 35%;
          text-align: center;
          z-index: 50;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 25;
          background-color: rgba(255,255,255,0.75);
          -moz-transition: all 1s linear;
          -ms-transition: all 1s linear;
          -o-transition: all 1s linear;
          -webkit-transition: all 1s linear;
          transition: all 1s linear;
        }
      }
    }
  }
  .reference-image {
    

    // &:hover {
    //   background-color: $c-curious-blue;
    //   opacity: 0.5;
    // }

    // &::after {
    //   content: '';
    //   background-color: $c-curious-blue;
    // }
    // &::before {
    //   content: '';
    //   background-color: $c-mulberry;
    // }
  }

  .references-filter {
    margin-bottom: map-get($spacers, 5);
    @include respond-below(sm) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .references-filter-item {
      margin-right: map-get($spacers, 5);
      @include respond-below(sm) {
        margin-right: 0;
      }
      .link {
        text-decoration: none;
        font-family: komet-sc,sans-serif;
        @include font-source-sans(18px, $c-black, 500, normal);

        &.is-active {
          text-decoration: underline;
          text-decoration-color: $c-mulberry;
          text-decoration-thickness: 2px;
          text-underline-offset: 9px
        }
      }
    }
  }
}

