.news {
    .news-article-date {
        font-family: komet-sc,sans-serif;
        @include font-source-sans(16px, #b9bfb5, 700, normal);
        margin-bottom: map-get($spacers, 2);
    }

    .news-article-image {
      margin-bottom: map-get($spacers, 3);
    }

    .news-article-headline {
        font-family: komet-sc,sans-serif;
        @include font-source-sans(30px, $h2-color, 300, normal);
        margin-bottom: map-get($spacers, 4);
    }


    .news-article-more {
    }

    &.detail {
      .news-article-image {
        margin-top: map-get($spacers, 6);
        margin-bottom: map-get($spacers, 5);
      }
  }
}
